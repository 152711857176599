<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Monthly Plan Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-wrap align-items-center">
              <div class="me-2">
                <label class="col-form-label me-1">From Date</label>
                <div style="width: 150px">
                  <flat-pickr
                    v-model="from_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    :config="flatPickrDateConfig"
                    @change="
                      getFilterData(
                        from_date,
                        to_date,
                        year,
                        employeeType,
                        moduleName,
                        trainer
                      )
                    "
                  ></flat-pickr>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label me-1">To Date</label>
                <div style="width: 150px">
                  <flat-pickr
                    v-model="to_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    :config="flatPickrDateConfig"
                    @change="
                      getFilterData(
                        from_date,
                        to_date,
                        year,
                        employeeType,
                        moduleName,
                        trainer
                      )
                    "
                  ></flat-pickr>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label me-1">Years</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="year"
                    :options="years"
                    :clearable="false"
                    :selectable="(options) => options.id != year?.id"
                    @option:selected="
                      getFilterData(
                        from_date,
                        to_date,
                        year,
                        employeeType,
                        moduleName,
                        trainer
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label me-1">Employee Type</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="employeeType"
                    :options="employeeTypes"
                    :clearable="false"
                    :selectable="(options) => options.id != employeeType?.id"
                    @option:selected="
                      getFilterData(
                        from_date,
                        to_date,
                        year,
                        month,
                        employeeType,
                        moduleName,
                        trainer
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label me-1">Module</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="moduleName"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id != moduleName?.id"
                    @option:selected="
                      getFilterData(
                        from_date,
                        to_date,
                        year,
                        month,
                        employeeType,
                        moduleName,
                        trainer
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div class="me-2">
                <label class="col-form-label me-1">Trainer</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="trainer"
                    :options="employeeLists"
                    :clearable="false"
                    :selectable="(options) => options.id != trainer?.id"
                    @option:selected="
                      getFilterData(
                        from_date,
                        to_date,
                        year,
                        month,
                        employeeType,
                        moduleName,
                        trainer
                      )
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div @click="refreshData" class="icon-css pt-4">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="dataTable"
                style="width: 1600px; margin-right: 1.2em"
                v-show="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th style="width: 150px">No.</th>
                    <th style="width: 200px">Module Group</th>
                    <th style="width: 200px">Employee Type</th>
                    <th style="width: 200px">Module</th>
                    <th style="width: 200px">From Date</th>
                    <th style="width: 200px">To Date</th>
                    <th style="width: 200px">Start Time</th>
                    <th style="width: 200px">Training Hour</th>
                    <th style="width: 200px">Trainer</th>
                    <th style="width: 200px">Training Type</th>
                    <th style="width: 200px">Location</th>
                    <th style="width: 200px" class="d-none">Budget Year</th>
                    <!-- <th class="d-none">Month</th> -->
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(item, index) in monthlyTrainingList" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.module_group_name }}</td>
                    <td>{{ item.employee_type }}</td>
                    <td>{{ item.module_name }}</td>
                    <td>{{ item.from_date }}</td>
                    <td>{{ item.to_date }}</td>
                    <td>{{ item.start_time }}</td>
                    <td>{{ item.training_hours }}</td>
                    <td>
                      {{
                        employeeLists
                          .filter((emp) => item.trainer.includes(emp.emp_id))
                          .map((trainer) => trainer.name)
                          .join(", ")
                      }}
                    </td>
                    <td>{{ item.training_type }}</td>
                    <td>{{ item.location }}</td>
                    <td class="d-none">{{ item.budget_year }}</td>
                    <!-- <td class="d-none">{{ getMonth(item.from_date) }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Orientation Criteria
import "datatables.net/js/jquery.dataTables.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import { months } from "../../../utlis/months";
// eslint-disable-next-line no-unused-vars
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    flatPickr,
  },
  data() {
    return {
      years: [],
      employeeTypes: [
        {
          id: 1,
          name: "Normal",
        },
        {
          id: 2,
          name: "Program",
        },
      ],
      modules: [],
      trainers: [],
      year: "",
      year: "",
      employeeType: "",
      from_date: "",
      to_date: "",
      moduleName: "",
      trainer: "",
      years: [],
      monthlyTrainingList: [],
      employeeLists: this.$store.getters["odoo/getAllEmployees"],
      title: "Monthly Plan Training Report Lists",
      headers: [
        "No.",
        "Module Group",
        "Employee Type",
        "Module",
        "From Date",
        "To Date",
        "Start Time",
        "Training Hour",
        "Trainer",
        "Training Type",
        "Location",
      ],
      flatPickrDateConfig: {
        minDate: null,
        maxDate: null,
        defaultDate: null,
      },
      forExportingData: null,
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },

  methods: {
    async getBudgetYear() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/yearly-training-plans`)
        .then((response) => {
          this.years = response.data.data;
          this.loading = false;
        });
    },
    async getAllEmployee() {
      this.loading = true;
      await axios.get(`${this.baseUrlHRIS}api/employees`).then((response) => {
        this.employeeLists = response.data.data;
        this.loading = false;
      });
    },
    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    async getAllMonthlyTraining() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/monthly-training-plan-report`)
        .then((response) => {
          this.monthlyTrainingList = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#dataTable").DataTable().draw();
    },

    getFilterData(from_date, to_date, year, employeeType, moduleName, trainer) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        let fromDateFilter = from_date;
        let fromDateStage = data[4];
        let toDateFilter = to_date;
        let toDateStage = data[5];
        let typeFilter = employeeType?.name;
        let typeStage = data[2];
        let moduleFilter = moduleName?.name;
        let moduleStage = data[3];
        let trainerFilter = trainer?.name;
        let trainerStage = data[8];
        let yearFilter = year?.name;
        let yearStage = data[11];
        if (fromDateFilter && toDateFilter) {
          const itemDate = new Date(data[5]);
          if (
            itemDate >= new Date(from_date) &&
            itemDate <= new Date(to_date) &&
            (yearFilter == yearStage || !yearFilter) &&
            (typeFilter == typeStage || !typeFilter) &&
            (moduleFilter == moduleStage || !moduleFilter) &&
            (trainerFilter == trainerStage || !trainerFilter)
          ) {
            return true;
          }
          return false;
        } else if (
          (fromDateFilter == fromDateStage || !fromDateFilter) &&
          (toDateFilter == toDateStage || !toDateFilter) &&
          (yearFilter == yearStage || !yearFilter) &&
          (typeFilter == typeStage || !typeFilter) &&
          (moduleFilter == moduleStage || !moduleFilter) &&
          (trainerStage.includes(trainerFilter) || !trainerFilter)
        ) {
          return true;
        }
        return false;
      });
      $("#dataTable").DataTable().draw();
    },
    refreshData() {
      this.from_date = "";
      this.to_date = "";
      this.moduleName = "";
      this.employeeType = "";
      this.trainer = "";
      this.year = "";
      this.monthlyTrainingList = [];
      this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
      this.clearFilter();
      this.getAllModules();
      this.getAllMonthlyTraining();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#dataTable").DataTable().draw();
    },
    exportPdfClick() {
      const dataArray = $("#dataTable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = [];

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[item.length - 1] = "";
        sheetData.push(item);
      });
      exportPdf(this.headers, sheetData, this.title);

      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#dataTable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const sheetData = [];

      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[item.length - 1] = "";
        sheetData.push(item);
      });
      exportExcel(sheetData, "Monthly Training List");
      this.excelLoading = false;
    },
  },
  async created() {
    this.clearFilter();
    if (this.$store.getters["odoo/getAllEmployees"].length > 0) {
      this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
    } else {
      await this.getAllEmployee();
    }
    this.getBudgetYear();
    this.getAllModules();
    await this.getAllMonthlyTraining();
    //
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
